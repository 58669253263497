import { z } from 'zod';
export const ssoDomainsGuard = z.array(z.string());
export const ssoBrandingGuard = z.object({
    displayName: z.string().optional(),
    logo: z.string().optional(),
    darkLogo: z.string().optional(),
});
export const idpInitiatedAuthParamsGuard = z
    .object({
    scope: z.string().optional(),
})
    .catchall(z.string());
export const ssoSamlAssertionContentGuard = z
    .object({
    nameID: z.string().optional(),
    attributes: z.record(z.string().or(z.array(z.string()))).optional(),
    conditions: z
        .object({
        notBefore: z.string().optional(),
        notOnOrAfter: z.string().optional(),
    })
        .optional(),
})
    .catchall(z.unknown());
