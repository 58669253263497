import { z } from 'zod';
import { SsoConnectors } from '../db-entries/sso-connector.js';
/**
 * SSO Connector data type that are returned to the experience client for sign-in use.
 */
export const ssoConnectorMetadataGuard = z.object({
    id: z.string(),
    connectorName: z.string(),
    logo: z.string(),
    darkLogo: z.string().optional(),
});
export var SsoProviderName;
(function (SsoProviderName) {
    SsoProviderName["OIDC"] = "OIDC";
    SsoProviderName["SAML"] = "SAML";
    SsoProviderName["AZURE_AD"] = "AzureAD";
    SsoProviderName["GOOGLE_WORKSPACE"] = "GoogleWorkspace";
    SsoProviderName["OKTA"] = "Okta";
    SsoProviderName["AZURE_AD_OIDC"] = "AzureAdOidc";
})(SsoProviderName || (SsoProviderName = {}));
export var SsoProviderType;
(function (SsoProviderType) {
    SsoProviderType["OIDC"] = "oidc";
    SsoProviderType["SAML"] = "saml";
})(SsoProviderType || (SsoProviderType = {}));
export const singleSignOnDomainBlackList = Object.freeze([
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'live.com',
    'icloud.com',
    'aol.com',
    'yandex.com',
    'mail.com',
    'protonmail.com',
    'yanex.com',
    'gmx.com',
    'mail.ru',
    'zoho.com',
    'qq.com',
    '163.com',
    '126.com',
    'sina.com',
    'sohu.com',
]);
const ssoConnectorProviderDetailGuard = z.object({
    providerName: z.nativeEnum(SsoProviderName),
    providerType: z.nativeEnum(SsoProviderType),
    logo: z.string(),
    logoDark: z.string(),
    description: z.string(),
    name: z.string(),
});
export const ssoConnectorProvidersResponseGuard = z.array(ssoConnectorProviderDetailGuard);
// API response guard for all the SSO connectors CRUD APIs
export const ssoConnectorWithProviderConfigGuard = SsoConnectors.guard
    // Must be a supported SSO provider name. Overwrite the providerName string type to enum.
    .extend({ providerName: z.nativeEnum(SsoProviderName) })
    .merge(
// Static provider details
z.object({
    name: z.string(),
    providerType: z.nativeEnum(SsoProviderType),
    providerLogo: z.string(),
    providerLogoDark: z.string(),
    // SSO connection config parsed from the provider.
    // - OIDC: connection config fetched from the OIDC provider.
    // - SAML: connection config fetched from the metadata url or metadata file.
    providerConfig: z.record(z.unknown()).optional(),
}));
export var SsoAuthenticationQueryKey;
(function (SsoAuthenticationQueryKey) {
    SsoAuthenticationQueryKey["SsoConnectorId"] = "ssoConnectorId";
})(SsoAuthenticationQueryKey || (SsoAuthenticationQueryKey = {}));
