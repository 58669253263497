// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { idpInitiatedAuthParamsGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    connectorId: z.string().min(1).max(128),
    defaultApplicationId: z.string().min(1).max(21),
    redirectUri: z.string().nullable().optional(),
    authParameters: idpInitiatedAuthParamsGuard.optional(),
    autoSendAuthorizationRequest: z.boolean().optional(),
    clientIdpInitiatedAuthCallbackUri: z.string().nullable().optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    connectorId: z.string().min(1).max(128),
    defaultApplicationId: z.string().min(1).max(21),
    redirectUri: z.string().nullable(),
    authParameters: idpInitiatedAuthParamsGuard,
    autoSendAuthorizationRequest: z.boolean(),
    clientIdpInitiatedAuthCallbackUri: z.string().nullable(),
    createdAt: z.number(),
});
export const SsoConnectorIdpInitiatedAuthConfigs = Object.freeze({
    table: 'sso_connector_idp_initiated_auth_configs',
    tableSingular: 'sso_connector_idp_initiated_auth_config',
    fields: {
        tenantId: 'tenant_id',
        connectorId: 'connector_id',
        defaultApplicationId: 'default_application_id',
        redirectUri: 'redirect_uri',
        authParameters: 'auth_parameters',
        autoSendAuthorizationRequest: 'auto_send_authorization_request',
        clientIdpInitiatedAuthCallbackUri: 'client_idp_initiated_auth_callback_uri',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'connectorId',
        'defaultApplicationId',
        'redirectUri',
        'authParameters',
        'autoSendAuthorizationRequest',
        'clientIdpInitiatedAuthCallbackUri',
        'createdAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
